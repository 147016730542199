<template>
  <b-container>
    <masthead title="Signatures" :show-search="false" />
    <b-row>
      <label> Select company</label>
      <b-form-select v-model="selectedCompany" :options="companies" />
    </b-row>
    <br>
    <div v-if="selectedCompany">
      <b-button size="sm" variant="primary" @click="showSignaturePad = !showSignaturePad">
        Create new signature
      </b-button>
    </div>
    <br>
    <div v-if="showSignaturePad">
      <b-row>
        <b-col>
          <b-form-group label="First name">
            <b-form-input v-model="firstName" :state="isValidInput(firstName)" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Last name">
            <b-form-input v-model="lastName" :state="isValidInput(lastName)" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Reason">
            <b-form-select
              v-model="reason"
              :options="signatureTypes"
              :state="isValidInput(reason)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="shadow border border-info rounded">
        <vue-signature-pad
          ref="signaturePad"
          width="100%"
          height="50%"
          :options="{ backgroundColor: 'rgba(248,249,250,1)' }"
        />
      </b-row>
      <b-row class="float-right">
        <b-button class="mb-5 mr-3" variant="secondary" @click="clearSignature()">
          Clear
        </b-button>
        <b-button class="mb-5" variant="primary" @click="saveSignature()">
          Save
        </b-button>
      </b-row>
    </div>
    <br class="break-mobile">
    <b-row v-if="signatures.length > 0">
      <b-col>
        <h3>Signatures</h3>
        <b-row
          v-for="signature in signatures"
          :key="signature.id"
          class="signature-mobile mt-3 "
        >
          <b-col>
            {{ capitalizeFirstLetter(signature.first_name) }}
            {{ capitalizeFirstLetter(signature.last_name) }} signed for
            {{ signature.signature_type }}
          </b-col>
          <b-col>
            <b-img thumbnail fluid :src="signature.file.join()" alt="Can't find image" />
          </b-col>
          <b-col>
            <b-button size="sm" variant="danger" @click="deleteSignature(signature.id)">
              Delete
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Masthead from '@/components/shared/Masthead'
import VueSignaturePad from 'vue-signature-pad'
import http from '../http'
import _ from 'lodash'

export default {
  name: 'Signature',
  components: { Masthead, VueSignaturePad },
  data() {
    return {
      selectedCompany: null,
      signatures: [],
      companies: [],
      showSignaturePad: false,
      firstName: '',
      lastName: '',
      reason: '',
      signatureTypes: [],
    }
  },
  watch: {
    selectedCompany: function(selection) {
      this.getSignatures(selection)
    },
  },
  mounted() {
    this.getCompanies()
    this.getSignatureTypes()
  },
  methods: {
    async getSignatures(companyId) {
      let result = await http.get(`client/companies/${companyId}/signatures`)
      this.signatures = result.data.result
    },
    async getCompanies() {
      let result = await http.get(`client/companies`)
      this.companies = result.data.result.map(company => {
        return { value: company.id, text: company.name }
      })
    },
    capitalizeFirstLetter(string) {
      return _.capitalize(string)
    },
    async deleteSignature(signatureId) {
      await http.delete(`client/companies/${this.selectedCompany}/signatures/${signatureId}`)
      await this.getSignatures(this.selectedCompany)
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature()
    },
    async saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (isEmpty || !this.isAllInputValid()) {
        this.$bvToast.toast('The signature form is missing required information', {
          title: 'Missing information',
          variant: 'danger',
          solid: true,
        })
        return
      }
      await http
        .post(`client/companies/${this.selectedCompany}/signatures`, {
          file: data,
          first_name: this.firstName,
          last_name: this.lastName,
          signature_type: this.reason,
        }).then(() => {
          this.showSignaturePad = false
        })
        .catch(() => {
          this.$bvToast.toast('Failed to add signature', {
            title: 'Failure',
            variant: 'danger',
            solid: true,
          })
        })
      this.getSignatures(this.selectedCompany)
    },
    isValidInput(input) {
      return input.length > 0
    },
    isAllInputValid() {
      return (
        this.isValidInput(this.firstName) &&
        this.isValidInput(this.lastName) &&
        this.isValidInput(this.reason)
      )
    },
    async getSignatureTypes() {
      let result = await http.get(`client/signature_types`)
      this.signatureTypes = Object.keys(result.data.result).filter((type) => {
        if (type === "Quote Authorization") return false
        return true
      }).map(type => {
        return { value: type, text: type }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 660px) {
  .signature-mobile {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    row-gap: 1.0em;
  }

  .break-mobile {
    display: none;
  }
}
</style>
