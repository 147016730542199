var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("masthead", { attrs: { title: "Signatures", "show-search": false } }),
      _c(
        "b-row",
        [
          _c("label", [_vm._v(" Select company")]),
          _c("b-form-select", {
            attrs: { options: _vm.companies },
            model: {
              value: _vm.selectedCompany,
              callback: function ($$v) {
                _vm.selectedCompany = $$v
              },
              expression: "selectedCompany",
            },
          }),
        ],
        1
      ),
      _c("br"),
      _vm.selectedCompany
        ? _c(
            "div",
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showSignaturePad = !_vm.showSignaturePad
                    },
                  },
                },
                [_vm._v("\n      Create new signature\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      _vm.showSignaturePad
        ? _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "First name" } },
                        [
                          _c("b-form-input", {
                            attrs: { state: _vm.isValidInput(_vm.firstName) },
                            model: {
                              value: _vm.firstName,
                              callback: function ($$v) {
                                _vm.firstName = $$v
                              },
                              expression: "firstName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Last name" } },
                        [
                          _c("b-form-input", {
                            attrs: { state: _vm.isValidInput(_vm.lastName) },
                            model: {
                              value: _vm.lastName,
                              callback: function ($$v) {
                                _vm.lastName = $$v
                              },
                              expression: "lastName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Reason" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              options: _vm.signatureTypes,
                              state: _vm.isValidInput(_vm.reason),
                            },
                            model: {
                              value: _vm.reason,
                              callback: function ($$v) {
                                _vm.reason = $$v
                              },
                              expression: "reason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "shadow border border-info rounded" },
                [
                  _c("vue-signature-pad", {
                    ref: "signaturePad",
                    attrs: {
                      width: "100%",
                      height: "50%",
                      options: { backgroundColor: "rgba(248,249,250,1)" },
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "float-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-5 mr-3",
                      attrs: { variant: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.clearSignature()
                        },
                      },
                    },
                    [_vm._v("\n        Clear\n      ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-5",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveSignature()
                        },
                      },
                    },
                    [_vm._v("\n        Save\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("br", { staticClass: "break-mobile" }),
      _vm.signatures.length > 0
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("h3", [_vm._v("Signatures")]),
                  _vm._l(_vm.signatures, function (signature) {
                    return _c(
                      "b-row",
                      {
                        key: signature.id,
                        staticClass: "signature-mobile mt-3",
                      },
                      [
                        _c("b-col", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.capitalizeFirstLetter(signature.first_name)
                              ) +
                              "\n          " +
                              _vm._s(
                                _vm.capitalizeFirstLetter(signature.last_name)
                              ) +
                              " signed for\n          " +
                              _vm._s(signature.signature_type) +
                              "\n        "
                          ),
                        ]),
                        _c(
                          "b-col",
                          [
                            _c("b-img", {
                              attrs: {
                                thumbnail: "",
                                fluid: "",
                                src: signature.file.join(),
                                alt: "Can't find image",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteSignature(signature.id)
                                  },
                                },
                              },
                              [_vm._v("\n            Delete\n          ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }